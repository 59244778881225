import React from 'react'
import six from '../static/tools/6.png'
import seven from '../static/tools/7.png'
import eight from '../static/tools/8.png'
import nine from '../static/tools/9.png'
import Layout from '../components/layout'

const imageMap = {
  0: seven,
  1: eight,
  2: six,
  3: nine,
}
const pageTitle = 'What Tools Do I Need?'
const pageSubtitle = 'Tools and materials aren’t included in the price. If you already make jewellery in metal, ' +
  'you can use the same tools for wax. If you\'re just starting out, get the essential tools to start with. ' +
  'You can always upgrade to the less essential tools (drills especially are expensive).\n\n' +
  'You can buy tools and materials from all jewellery suppliers online or in shops. The one I use in Europe is Cooksongold ' +
  'and a big one in the US is Rio Grande. Have a google though, every country has their own suppliers. Cooksongold sells wax starter kits, so have a look for those at other suppliers as well.'
const texts = [
  {
    sectitle: '',
    title: 'Essential tools',
    text: '',
    bulletTitle: '',
    bullets: ['Jewellers saw and blades',
      'Bench pin',
      'Wax ring sizer',
      'Ring stick',
      'Dividers',
      'Ruler',
      'Hand files',
      'Scalpel',
      'Fine tip permanent marker',]
  }, {
    sectitle: '',
    title: 'Less Essential',
    text: '',
    bulletTitle: '',
    bullets: ['Small unit Scale',
      'Callipers/vernier gauge (something to measure your wax)',
      '(Wax) needle files',
      'Foredom/micromotor (something to drill) and burrs',
      'Set square',
      'Wax carving tools',
      'Wax pen',
      'Wax sawblades',]
  }, {
    sectitle: '',
    title: 'Wax',
    text: 'I use blue wax, but get a different color if you feel like it.',
    bullets: [
      'Round ring wax tube with centre hole',
      'Flat top ring wax tube with centre hole',
      'Wax block or wax slices (slices are easier, less prep work)',
      'Wax bracelet bar (decide yourself if you want oval or round bangles)'
    ]
  }, {
    sectitle: '',
    title: 'Gemstones',
    text: 'Please just use whatever gemstone you have lying around for the projects. If you don’t have any gemstones, get CZs as long as you are practicing.\n\nGemstones covered are cabochons, rosecuts, faceted and beads. Oval and round stones are going to be easiest and bigger stones easier than smaller. If you want a challenge, get a stone with a point as well.',
    bullets: [
      'Faceted',
      'Cabochons',
      'Rosecut',
      'Beads',
    ]
  },
]

const Bullet = ({ text }) => <li className="mt-2">
  <div className="flex">
    <div className="flex-shrink-0">
      <div
        className="flex items-center justify-center h-8 w-8 rounded-md bg-blue-700 text-white">
        <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clipRule="evenodd" />
        </svg>
      </div>
    </div>
    <div className="ml-4">
      <p className="text-base leading-6 text-gray-700">
        {text}
      </p>
    </div>
  </div>
</li>

const ToolsOne = ({ secTitle, title, text, bulletTitle, bullets, index }) =>
  <>
    <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div className="relative">
        <h4 className="text-2xl leading-8 font-bold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
          {title}
        </h4>
        <p className="mt-3 prose prose-indigo prose-lg text-gray-700 whitespace-pre-wrap">
          {text}
        </p>
        <div className="mt-8 text-xl sm:leading-9">
          {bulletTitle}
        </div>

        <ul className="mt-10">
          {bullets && bullets.map(it => <Bullet text={it} key={it} />)}
        </ul>
      </div>

      <div className="mt-10 -mx-4 relative lg:mt-0">
        <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784"
             height="404" fill="none" viewBox="0 0 784 404">
          <defs>
            <pattern id="ca9667ae-9f92-4be7-abcb-9e3d727f2941" x="0" y="0" width="20" height="20"
                     patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
        </svg>
        <img className="relative mx-auto rounded-lg" width="490"
             src={imageMap[index]} alt="" />
      </div>
    </div>

    <svg className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404"
         height="784" fill="none" viewBox="0 0 404 784">
      <defs>
        <pattern id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d" x="0" y="0" width="20" height="20"
                 patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
        </pattern>
      </defs>
      <rect width="404" height="484" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
    </svg>
  </>

const ToolsTwo = ({ secTitle, title, text, bulletTitle, bullets, index }) =>
  <div className="relative mt-12 sm:mt-16 lg:mt-24">
    <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div className="lg:col-start-2">
        <h4 className="text-2xl leading-8 font-bold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
          {title}
        </h4>
        <p className="mt-3  prose prose-indigo prose-lg text-gray-700 whitespace-pre-wrap">
          {text}
        </p>
        <div className="mt-8 text-xl sm:leading-9">
          {bulletTitle}
        </div>
        <ul className="mt-10">
          {bullets && bullets.map(it => <Bullet text={it} key={it} />)}
        </ul>
      </div>

      <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
        <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
             width="784"
             height="404" fill="none" viewBox="0 0 784 404">
          <defs>
            <pattern id="e80155a9-dfde-425a-b5ea-1f6fadd20131" x="0" y="0" width="20" height="20"
                     patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200"
                    fill="currentColor" />
            </pattern>
          </defs>
          <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
        </svg>
        <img className="relative mx-auto rounded-lg" width="490"
             src={imageMap[index]} alt="" />
      </div>
    </div>
  </div>

const Tools = () => (
  <Layout>
    <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
      <div className="relative">
        <h1 className="text-center text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-3xl sm:leading-10">
          {pageTitle}
        </h1>
        <p className="mt-4 max-w-2xl mx-auto  prose prose-indigo prose-lg text-gray-700 whitespace-pre-wrap">
          {pageSubtitle}
        </p>
        <div className="text-center text-xl  mt-6">
          <a href="https://www.cooksongold.com" target="_blank" rel="noreferrer noopener"
             className="text-indigo-400">https://www.cooksongold.com</a> |&nbsp;
          <a href="https://www.riogrande.com/" target="_blank" rel="noreferrer noopener"
             className="text-indigo-400">https://www.riogrande.com</a>
        </div>
      </div>

      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
        <svg className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404"
             height="784" fill="none" viewBox="0 0 404 784">
          <defs>
            <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20"
                     patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>
        {texts.map((it, idx) => idx % 2 === 0
          ? <ToolsOne key={it.title} {...it} index={idx} />
          : <ToolsTwo key={it.title} {...it} index={idx} />
        )}
      </div>
    </div>
  </Layout>
)

export default Tools
